module.exports = {
  'en': 'EN',
  'zh-mo': '中文',
  'btn.moreDetail': 'More Detail',
  'introduction': 'Track your needs',
  'introduction.subtitle': 'TrackDx Company Limited is founded in Macau and is a professional service provider and products Marketing&Sales company',
  'services': 'Services',
  'ourServices': 'The Services we offer',
  'mission': 'Mission',
  'description': 'TrackDx Company Limited is founded in Macau and is a professional service provider and products Marketing&Sales company',
  'selectLanguage': 'Select Language',
  'introduction.p1': "TrackDx Company Limited is founded in Macau and is a professional service provider and products Marketing&Sales company. We aim to import the excellent products to local market and provide market-oriented sales strategies for companies and brands. Our services cover testing logistics, consultation, import and export trading, exhibition service.",
  'introduction.p2': 'We value products so we know well to create products value in markets. We co-work with companies and brands to identify the value proposition for products. Our excellent marketing/sales team members are very experienced in sales and marketing. Collaboration with TrackDx enhances the sales and market share of your excellent products.',
  'contact': 'Need more details? Contact us',
  'service.logistics': 'Testing Logistics',
  'service.logistics.detail': 'Customized testing services for local customer and provide the logistic service. The captioned logistic services included: cancer-related genes(insights for improve diagnosis and prognosis), reproductive health genes,paediatric genetic screening etc. which requested by physicians.',
  'service.marketing': 'Import and Export Trading',
  'service.marketing.detail': 'Import the excellent products to local market and provide market-oriented sales strategies for companies and brands. Working with companies and brands to identify the value proposition for products. Collaboration with TrackDx enhances the sales and market share of your excellent products.',
  'service.exhibition': 'Exhibition Service',
  'service.exhibition.detail': 'From scientific symposiums to casual luncheons, and from internal company events to international conferences, TrackDx can plan, arrange, manage and execute any events you require.',
  'service.consult': 'Specialist Customized Solutions',
  'service.consult.detail': 'We value products and understand the local market. We know the issues, people and the science. With our can-do attitude and network of contacts, we help you craft a campaign tailored to your needs.',
  'missions.title': 'Mission, Vision and Values',
  'mission.detail': 'The mission of TrackDx is establishing trustworthy services and sales channels with the aim of create win-win situations for our customers and suppliers.',
  'vision.detail': 'Our vision is to be considered as the best candidate for agent, distributor or strategic partner for customers who keen on develop Macau market. ',
  'value.detail': 'Our value is to provide holistic supports to fulfilled the requests from customers in an effective and efficient way.',
  'callUSNow': 'We are here to assist. Contact us by phone or email.',
  'phone': 'Phone',
  'email': 'Email'
};
